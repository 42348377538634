import * as React from "react";
import {useEffect, useState} from "react";
import moment from "moment";
import axios from "axios";

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import "../style.scss"

interface TProduct {
  sum: { min: number; max: number; step: number },
  period: { min: number; max: number; step: number }
}
interface TStep {
	from: number,
	to: number,
	step: number,
	front: string,
	client: string,
}

const App = (): JSX.Element => {
	const appRoot = document.getElementById('calc');

	const isDisabled = appRoot && appRoot.getAttribute('data-disableinputs') === 'true';

	const [state, setState] = useState({
		response: null,
		currentSum: null,
		defaultMax: null,
		inputSum: null,
		prevSum: null,
		currentPeriod: null,
		promoCode: '',
		sum: {
			min: null,
			max: null
		},
		period: null,
		actProduct: null,
		steps: null,
		marks: null
	})
	const setStateHandler = (key: string, value: number | string) => {
		setState(prevState =>
			({...prevState, [key]: value})
		);
	};
	const submitHandler = () => {

		const data = {
			amount: state.currentSum,
			period: state.currentPeriod,
			product: state.actProduct?.type ? state.actProduct?.type : state.actProduct?.productName,
			promo_code: state.promoCode,
			variant_id: state.actProduct?.productId,
			// @ts-ignore
			payment_card: document.querySelector('#payment_card')?.value,
		}
		// @ts-ignore
		$.post('/calculator/save', data, () => {
			const elem = document.getElementById('redirectUri');
			window.location.replace(elem.dataset.uri);
		}, 'json');
		// axios.post("/calculator/get-calc-data", data).then(() => {
		// 	let elem = document.getElementById('redirectUri');
		// 	window.location.replace(elem.dataset.uri);
		// })
	}
	// корректное склонение слова "день"
	const getNoun = (number: number) => {
		let n = Math.abs(number);
		n %= 100;
		if (n >= 5 && n <= 20) {
			return 'дней';
		}
		n %= 10;
		if (n === 1) {
			return 'день';
		}
		if (n >= 2 && n <= 4) {
			return 'дня';
		}
		return 'дней';
	};
	// расчет даты возврата
	const calculateReturnDate = () => {
		const date = moment().add(state.currentPeriod, "days");
		return moment(date).format("DD.MM.YYYY");
	}
	// форматируем число в валюту с разделением
	const numberFormat = (number: number) => {
		const newFormat = new Intl.NumberFormat("ru-RU").format(number);
		return newFormat + " ₽";
	};
	// расчет сыммы возврата для инсталлмент
	const calculateReturnSumInst = (daysInCurrentYear: number) => {
		// если продукт не "стандартный"
		const {payments, periodDays} = state.actProduct;

		const paymentPsp = ((state.actProduct.percent / daysInCurrentYear) * periodDays) / 100;

		return Math.round(state.currentSum *
      (paymentPsp + (paymentPsp / (Math.pow(1 + paymentPsp, payments) - 1))
      )
		)
	}
	// расчет суммы возврата
	const calculateReturnSum = () => {
		// количество дней в текущем году
		const daysInCurrentYear = new Date().getFullYear() % 4 === 0 ? 366 : 365;

		if (state.actProduct.productName !== "Стандартный") return calculateReturnSumInst(daysInCurrentYear)
		let result = state.currentSum;
		if (!document.body.contains(document.getElementById('home-page__zero_loan'))) {
			result = Math.round(Number(state.currentSum) + (
				state.currentSum * 0.01 * state.actProduct.percent * (state.currentPeriod - 1)
			) / daysInCurrentYear);
		}
		return result;

	}
	// округляем сумму до шага
	const roundSum = (sum: number) => {
		return Math.round(sum / state.actProduct.sum.step) * state.actProduct.sum.step;
	}
	// округляем период
	const roundPeriod = (goal: number) => {
		return state.period.reduce(function (prev: number, curr: number) {
			return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
		});
	}
	useEffect(() => {
		axios.get("/calculator/get-calc-data").then(res => {
			//сортируем по периоду
			const {products} = res.data
			products.sort((a: TProduct, b: TProduct) => a.period.max - b.period.max);
			// находим минимальную или максимальную сумму займа для калькуляторп
			const extremeProductSum = (value: TProduct[], side = "min") => {
				return value.reduce(function (prev, curr) {
					if (side === "max") return prev.sum.min > curr.sum.min ? prev : curr;
					return prev.sum.min < curr.sum.min ? prev : curr;
				});
			}
			// минимальная сумма займа
			const productMinSum = extremeProductSum(products)
			// максимальная сумма займа
			const productMaxSum = extremeProductSum(products, "max")
			// формируем массив периода для передачи в Range пропсом points
			const preparePeriod = () => {
				const out: number[] = [];
				const range = (start: number, end: number) => {
					while (start <= end) {
						out.push(start++);
					}
				}
				for (let i = 0; i < products.length; i++) {
					const elPeriod = products[i].period;
					if (elPeriod.min < elPeriod.max) {
						range(elPeriod.min, elPeriod.max)
					} else {
						out.push(elPeriod.min)
					}
				}
				return out;
			}
			const defaultSum = (res.data.settings && res.data.settings.amount)?res.data.settings.amount:res.data.defaultSum;

			const getActProduct = () => {
				return products[0];
			}

			const defaultStep = () => {
				const product = getActProduct();
				return product.sum?.step;
			}

			const calcMarks = () => {
				let marks: number[] = [];
				const increment = defaultStep();
				const min = productMinSum.sum.min;
				const max = productMaxSum.sum.max;
				for(let i = min; i < max; i+=increment)
					marks.push(i);
				marks.push(max);

				if(res.data.settings && res.data.settings.steps)
					res.data.settings.steps.forEach((step: TStep)=>{
						marks = marks.filter((mark:number) => mark < step.from || mark > step.to);
						for(let i = step.from; i < step.to; i+=step.step)
							marks.push(i);
						marks.push(step.to);
					})

				marks.sort((a, b) => { return a - b; });

				const result : any = {};
				marks.forEach((mark) => {
					result[mark] = '';
				});

				return result;
			}

			setState({
				response: res.data,
				sum: {
					min: productMinSum.sum.min,
					max: productMaxSum.sum.max,
				},
				period: preparePeriod(),
				currentSum: defaultSum,
				defaultMax: defaultSum,
				inputSum: defaultSum,
				prevSum: defaultSum,
				currentPeriod: res.data.defaultPeriod,
				actProduct: getActProduct(),
				promoCode: '',
				steps: (res.data.settings && res.data.settings.steps)?res.data.settings.steps:null,
				marks: calcMarks()
			})
		})
	}, [])
	useEffect(() => {
		document.querySelector("#calculator-save").addEventListener('click', submitHandler)
		return () => {
			document.querySelector("#calculator-save").removeEventListener("click", submitHandler);
		};
	})
	// ждем ответа с сервера
	if (!state.sum || !state.period) return (
		<div className="calc">
			<p className="calc__loading">Идет загрузка</p>
		</div>
	)

	// при изменении суммы
	const onSumChange = (value: number) => {
		setState(prevState =>
			(
				{
					...prevState,
					currentSum: value,
					prevSum: state.currentSum,
					inputSum: value,
				}
			)
		);

		const {products} = state.response;
		// работаем с копией массива продуктов чтобы не мутировать его
		const cloneProducts = products.slice()

		let actProd = cloneProducts.find((element: TProduct) => (
			state.currentPeriod >= Number(element.period.min) &&
      state.currentPeriod <= Number(element.period.max) &&
      value >= Number(element.sum.min) &&
      value <= Number(element.sum.max)
		))
		/*
    * если продукт с данным значением суммы и периода находит среди массива продуктов
    * записываем его в состояние
    * */
		if (actProd) {
			setStateHandler("actProduct", actProd)
			return
		}
		/*
    * если предыдущая сумма больше чем текущее значение инпута суммы
    * ползунок идет в обратную сторону
    * нам нужно развернуть массив продутов
    * чтобы искать продукты с большим периодом
    * */
		if (state.currentSum > value) cloneProducts.reverse();
		// ищем другой продукт с подходящим периодом
		actProd = cloneProducts.find((element: TProduct) => (
			value >= Number(element.sum.min) &&
      value <= Number(element.sum.max)
		))
		setState(prevState =>
			(
				{
					...prevState,
					currentPeriod: state.currentSum < value ? actProd.period.min : actProd.period.max,
					actProduct: actProd
				}
			)
		);

	}
	const onChangeInputSum = (val: string) => {
		const inputValue = Number(val);
		if (val.length > 6) {
			return;
		}
		if (inputValue > 0) {
			setStateHandler("inputSum", inputValue);
		} else {
			setStateHandler("inputSum", '');
		}
		return;
	}
	const onChangeInputPeriod = (val: string) => {
		const inputValue = Number(val);
		if (val.length > 3) {
			return;
		}
		if (inputValue > 0) {
			setStateHandler("currentPeriod", inputValue);
		} else {
			setStateHandler("currentPeriod", '');
		}
		return;
	}
	const onBlurInputSum = (val: number) => {
		const toNum = Number(val)
		let correctVal: number;
		// если число из инпута меньше минимального
		if (toNum < state.sum.min) correctVal = state.sum.min
		// если число из инпута больше максимального
		else if (toNum > state.sum.max) correctVal = state.sum.max
		// округляем число из инпута до шага продукта
		else correctVal = roundSum(toNum)
		// записываем корректное значение после фильтрации выше в состояние

		const {currentSum} = state
		setState(prevState =>
			(
				{
					...prevState,
					currentSum: correctVal,
					inputSum: correctVal,
					prevSum: state.currentSum,
				}
			)
		);

		const {products} = state.response;
		// работаем с копией массива продуктов чтобы не мутировать его
		const cloneProducts = products.slice()

		let actProd = cloneProducts.find((element: TProduct) => (
			state.currentPeriod >= Number(element.period.min) &&
      state.currentPeriod <= Number(element.period.max) &&
      correctVal >= Number(element.sum.min) &&
      correctVal <= Number(element.sum.max)
		))
		/*
    * если продукт с данным значением суммы и периода находит среди массива продуктов
    * записываем его в состояние
    * */
		if (actProd) {
			if (actProd.productName !== state.actProduct.productName) setStateHandler("actProduct", actProd)
			return
		}
		/*
    * если предыдущая сумма больше чем текущее значение инпута суммы
    * ползунок идет в обратную сторону
    * нам нужно развернуть массив продутов
    * чтобы искать продукты с большим периодом
    * */
		if (correctVal < currentSum) cloneProducts.reverse();
		// ищем другой продукт с подходящим периодом
		actProd = cloneProducts.find((element: TProduct) => (
			correctVal >= Number(element.sum.min) &&
      correctVal <= Number(element.sum.max)
		))
		setState(prevState =>
			(
				{
					...prevState,
					currentPeriod: state.currentSum < correctVal ? actProd.period.min : actProd.period.max,
					actProduct: actProd
				}
			)
		);

	}

	// при изменении периода
	const onPeriodChange = (value: number) => {
		setState(prevState =>
			(
				{
					...prevState,
					currentPeriod: value,
				}
			)
		);

		const {products} = state.response;
		const cloneProducts = products.slice()
		let actProd = cloneProducts.find((element: TProduct) => (
			value >= Number(element.period.min) &&
      value <= Number(element.period.max) &&
      state.currentSum >= Number(element.sum.min) &&
      state.currentSum <= Number(element.sum.max)
		))
		/*
    * если продукт с данным значением суммы и периода находит среди массива продуктов
    * записываем его в состояние
    * */
		if (actProd) {
			setStateHandler("actProduct", actProd)
			return
		}

		actProd = cloneProducts.find((element: TProduct) => (
			value >= Number(element.period.min) &&
      value <= Number(element.period.max)
		))
		setState(prevState =>
			(
				{
					...prevState,
					currentSum: state.currentSum <= actProd.sum.min ? actProd.sum.min : actProd.sum.max,
					inputSum: state.currentSum <= actProd.sum.min ? actProd.sum.min : actProd.sum.max,
					actProduct: actProd
				}
			)
		);
	}
	const onBlurInputPeriod = (val: number) => {
		if (isDisabled && val > state.defaultMax) {
			val = state.defaultMax;
		}
		const toNum = Number(val)
		let correctVal: number;
		if (toNum < state.period[0]) correctVal = state.period[0]
		else if (toNum > state.period[state.period.length - 1]) correctVal = state.period[state.period.length - 1]
		else correctVal = roundPeriod(toNum)
		setStateHandler("currentPeriod", correctVal)
		onPeriodChange(correctVal)
	}

	/*const getStep = () => {
		console.log('1: '+state.currentSum+' : '+state.prevSum+' : '+state.inputSum);
		if(state.steps){
			const step = state.steps.find((step) => step.from <= state.currentSum && step.to >= state.currentSum);
			if(step) {
				console.log(step.step);
				return step.step;
			}
		}
		console.log(state.actProduct.sum?.step);
		return state.actProduct.sum?.step;
	}*/

	return (
		<div className="calc">
			<div className="calc__wrapper">
				<div className="calc__range">
					<div className="calc__input-wrapper">
						<input
							className="calc__input calc__input--sum"
							id="input__sum"
							type="number"
							value={state.inputSum}
							onChange={(event) => {
								const newValue = parseInt(event.target.value);
								if (!isDisabled || newValue <= state.defaultMax) {
									setStateHandler("inputSum", newValue);
								}
							}}
							onBlur={(event) => onBlurInputSum(Number(event.target.value))}
							max={isDisabled ? state.defaultMax : state.sum.max}
						/>
						<label className="calc__input-label calc__input-label--sum" htmlFor="input__sum">₽</label>
					</div>

					<Slider
						value={state.currentSum}
						min={state.sum.min}
						max={isDisabled ? state.defaultMax : state.sum.max}
						marks={state.marks}
						step={state.marks?null:state.actProduct.sum?.step}
						onChange={(value) => onSumChange(value)}
					/>
				</div>
				<div className="calc__range range__period">
					<div className="calc__input-wrapper">
						<input
							className="calc__input calc__input--period"
							id="input__period"
							value={state.currentPeriod}
							type="text"
							onChange={(event) => onChangeInputPeriod(event.target.value)}
							/*onChange={(event) => {
                setStateHandler("currentPeriod", Number(event.target.value))
              }}*/
							onBlur={(event) => onBlurInputPeriod(Number(event.target.value))}
							disabled={isDisabled}
						/>
						<label className="calc__input-label" htmlFor="input__period">{getNoun(state.currentPeriod)}</label>
					</div>
					{/*
						Мы преобразуем все точки
					*/}
					<Slider
						value={state.period.indexOf(state.currentPeriod)}
						min={0}
						max={state.period.length - 1}
						step={1}
						onChange={(value) => {
							const curVal = state.period[value]
							onPeriodChange(curVal)
						}}
						disabled={isDisabled}
					/>
				</div>
				<input
					className="calc__promoCode"
					placeholder="Введите промокод"
					id="promo_code"
					type="text"
					value={state.promoCode}
					onChange={(event) => setStateHandler("promoCode", event.target.value)}
					disabled={isDisabled}
				/>
				<div className="calc__payment">
					<p className="calc__payment-desc">
            До (включительно)
					</p>
					<p className="calc__payment-val">
						{calculateReturnDate()}
					</p>
				</div>
				<div className="calc__payment">
					<p className="calc__payment-desc">
						{state.actProduct.productName !== "Стандартный"
							? state.actProduct.periodDays === 30 ? "Платеж 1 раз в месяц":  "Платеж раз в 2 недели"
							: "Вернуть"}
					</p>
					<p className="calc__payment-val">
						{numberFormat(calculateReturnSum())}
					</p>
				</div>
				{/*<div className="calc__btn btn-green"*/}
				{/*	onClick={() => submitHandler()}>*/}
				{/*	Получить деньги*/}
				{/*</div>*/}
			</div>
		</div>
	);
}

export default App
